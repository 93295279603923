import { library } from "@fortawesome/fontawesome-svg-core";
import { faBan, faBarcodeScan, faBars, faBox, faCheckCircle, faQuestionCircle, faRotateLeft, faTimes } from "@fortawesome/pro-regular-svg-icons";

library.add(
	faTimes, 
	faQuestionCircle, 
	faBarcodeScan,
	faBars,
	faBox,
	faBan,
	faCheckCircle,
	faRotateLeft
);