/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { CustomError } from "./custom-error";
import { log, logWarning, logError } from "./log";

export class LocalizationUtilities {
	/**
	 * TAG for logging output.
	 */
	private static TAG: string = "Localization";

	private static currentLanguage: string = "";

	private static language: string = "en";

	/**
	 * List of languages that we currently support.
	 */
	private static supportedLanguages: Array<string> = ["en", "de", "nl", "fr"];
	
	/**
	 * Lookup table that will be populated with the language resource strings.
	 */
	private static localizedTextLookupTable: Record<string, string> = {
	};

	public static getLocalizedText(namespace: string, key: string, placeholderValues?: Array<string | number>): string {
		// Combine the namespace and key to create a kebab-case lookup for the target value, eg home-activity.welcome-message or button.cancel etc
		const target: string = `${namespace}.${key}`;
		let result: string = this.localizedTextLookupTable[target];
	
		if (result === undefined || result === null)	{
			// Return a marker to indicate there is a missing resource string.
			return `!! ${target} !!`;
		}
	
		// Substitute any placeholder markers in the localized string with the values provided.
		// eg $1 gets replaced with placeholderValues[0], $2 gets replaced with placeholderValues[1] etc
		if (placeholderValues !== undefined && placeholderValues.length > 0) {
			// Arrays usually start at 0, but using an offset of 1 is more user-friendly for the non-devs who will be creating the language files.
			const START_INDEX: number = 1;
			for (let position: number = START_INDEX; position <= placeholderValues.length; position++) {
				const placeholder: string = "{" + position.toString() + "}";
				let index: number = 0;
				while (true) {
					index = result.indexOf(placeholder);
					if (index < 0) {
						// No more placeholders left to substitute.
						break;
					}
					else	{
						const replacement: string = placeholderValues[position - START_INDEX].toString();
						result = result.replace(placeholder, replacement);
						// Advance past the replacement to avoid recursion should the replacement also contain a placeholder (it never should).
						index = index + replacement.length;
					}
				}
			}
		}
		return result;
	}

	/**
	 * Merge the alternative language data into the default lookup table.
	 */
	private static mergeAlternativeLanguage(alternativeLanguageData: Record<string, string>): void {
		for (const key of Object.keys(alternativeLanguageData)) {
			this.localizedTextLookupTable[key] = alternativeLanguageData[key];
		}
	}

	/**
	 * Loads the specified json language file asynchronously.
	 */
	private static async loadLanguage(languageCode: string): Promise<Record<string, string>> {
		let result: Record<string, string> = { };
	
		try {
			const response: Response = await fetch(`/languages/${languageCode}.json`); 
			if (response.ok) {
				result = await response.json() as Record<string, string>;	
				this.currentLanguage = languageCode;
			}
		}
		catch (e) {
			logWarning(this.TAG, `Failed to load language code ${languageCode}: Error ${e}`);
		}
		return result;
	}

	public static async loadPreferredLanguageAsync(): Promise<boolean> {
		const defaultLanguage: string = "en";
		// Load the default language file first.
		this.localizedTextLookupTable = await this.loadLanguage(defaultLanguage);
	
		let preferredLanguage: string = defaultLanguage;
		// We are only interested in the language code which is found in the first 2 characters of the locale.
		const LANGUAGE_CODE_PREFIX_LENGTH: number = 2;
		try {
			const target: string | undefined = this.language;
			if (target && this.supportedLanguages.includes(target)) {
				preferredLanguage = target;
			}
		}
		catch (error) {
			logError(this.TAG, error as CustomError);
		}
		// If we support the user's preferred language and its different to the default, load it and merge it in.
		if (preferredLanguage !== defaultLanguage) {
			log(this.TAG, `Loading preferred language '${preferredLanguage}'`);
			const alternativeLanguageData: Record<string, string> = await this.loadLanguage(preferredLanguage);
			this.mergeAlternativeLanguage(alternativeLanguageData);
		}
		return true;
	}
}
