import type { EndpointResponse } from "@/data/endpoint/types";
import { EndpointRequest } from "@/data/endpoint/endpoint-request";
import { endpointFetchAsync } from "@/data/endpoint/endpoint";
import { reactive } from "vue";
import { signOut } from "./authentication-provider";

// User model
export interface UserModel {
	id?: number;
	name?: string;
	email?: string;
	preferred_name?: string;
	language?: string;
	registered?: boolean;
	default_division_id?: number;
	divisions?: Array<Division>;
}

export interface Language {
	id: number;
	key: string;
	description: string;
}

// Division model
export interface Division {
	id: number;
	name: string;
	iso4217_currency_code: string;
	logo?: string;
	use_public_api: boolean;
	configuration_status: string;
	telephone_number: string;
}

// Path for user endpoint
const API_PATH: string = "users";

// Stores the user state
class User {
	public current: UserModel | undefined;
	public divisionID: number | undefined;
	public requiresSignup: boolean = false;
	public onboardingStatus: string = "";
	public token?: string;
} 

// Exports user state
export const user: User = reactive(new User);

/**
 * Create endpoint request for current user
 */
export async function getUserAsync(): Promise<EndpointResponse<UserModel>> {
	return endpointFetchAsync("GET", new EndpointRequest(`${API_PATH}/current`, false, undefined, true));
}

/**
 * Edit current user details
 */
export async function editUserAsync(body: BodyInit): Promise<EndpointResponse<UserModel>> {
	return endpointFetchAsync("PATCH", new EndpointRequest(`${API_PATH}/current`, false, body));
}

/**
 * Edit current division details
 */
export async function editDivisionAsync(body: BodyInit): Promise<EndpointResponse<Division>> {
	return endpointFetchAsync("PATCH", new EndpointRequest(`divisions/current`, false, body));
}

/**
 * Create endpoint request for getting languages
 */
export async function getLanguagesAsync(): Promise<EndpointResponse<Array<Language>>> {
	return endpointFetchAsync("GET", new EndpointRequest("languages"));
}

/**
 * Get user details from API and set current user state
 * @param {any} reject
 */
export async function getUserDetailsFromApi(): Promise<void> {
	await getUserAsync().then(async (response: EndpointResponse<UserModel>) => {
		if (response.wasSuccessful && response.data) {
			user.current = response.data;
			user.divisionID = response.data.default_division_id;
		}
		else {
			if (response.error) {
				if (response.error.code === 403) {
					user.requiresSignup = true;
				}
				else {
					await signOut();
				}
			}
		}
	});
}

/**
 * Match the current division against the divisions array
 */
export function getCurrentDivision(): Division | undefined {
	const current: Division | undefined = user.current?.divisions?.find((item: Division) => {
		return item.id === user.divisionID;
	});

	return current;
}