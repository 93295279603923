import { cva } from "class-variance-authority";

// Component model for the heading component
class FcHeadingModel {
	/**
	 * Returns list of classes for a specific heading variant
	 */
	public variants: Function = cva(["font-rubik"], {
		variants: {
			size: {
				"xs": "text-xs",
				"sm": "text-sm",
				"normal": "text-base",
				"md": "text-md",
				"lg": "text-lg",
				"xl": "text-xl",
				"2xl": "text-2xl",
				"3xl": "text-3xl",
				"4xl": "text-4xl",
				"5xl": "text-5xl",
				"6xl": "text-6xl",
				"7xl": "text-7xl",
				"8xl": "text-8xl",
				"9xl": "text-9xl"
			},
			color: {
				blackcurrant: "text-blackcurrant-500",
				gray: "text-gray-500"
			},
			weight: {
				light: "font-light",
				normal: "font-normal",
				medium: "font-medium",
				semibold: "font-semibold",
				bold: "font-bold",
				black: "font-black",

			},
			align: {
				left: ["w-full", "text-left"],
				center: ["w-full", "text-center"],
				right: ["w-full", "text-right"],
			},
			isFullWidth: {
				true: "w-full"
			}
		},
		defaultVariants: {
			size: "normal",
			color: "blackcurrant",
			weight: "normal"
		}
	});
}

// Export the component model
export const component: FcHeadingModel = new FcHeadingModel();