import { EndpointResponse } from "@/data/endpoint/types";
import * as Endpoint from "@/data/endpoint/endpoint";
import { CarrierServiceModel } from "../models/carrier-service-model";
import { StoreCageModel } from "../models/store-cage-model";

// ----------------------------------------------------
// Provides data access functions for store cages.
// ----------------------------------------------------

/**
 * Get a list of compatiable carrier services.
 */
export async function getCompatibleCarrierServicesAsync(carrier_service_id: number, store_id: number): Promise<EndpointResponse<Array<CarrierServiceModel>>> {
	return Endpoint.getAsync(`store_cages/compatible_carrier_services?carrier_service_id=${carrier_service_id}&store_id=${store_id}`);
}

/**
 * Get a list of store cages.
 */
export async function getStoreCagesAsync(id: number, barcode: string, carrier_service_id: number): Promise<EndpointResponse<Array<StoreCageModel>>> {
	return Endpoint.getAsync(`stores/${id}/store_cages?code=${barcode}&carrier_service_id=${carrier_service_id}`);
}