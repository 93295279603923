/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
import { CustomError } from "./custom-error";

export const LOG_LEVEL_NONE: number = 0;
export const LOG_LEVEL_INFORMATION: number = 1;
export const LOG_LEVEL_WARNING: number = 2;
export const LOG_LEVEL_ERROR: number = 3;

// Default to just log errors.
let logLevel: number = LOG_LEVEL_ERROR;

function padLeft(value: number, minimumLength: number): string {
	const result: string = value.toString();
	if (result.length >= minimumLength) {
		return result;
	}
	else {
		const padding: string = "0".repeat(minimumLength - result.length);
		return `${padding}${result}`;
	}
}

function formatMessage(tag: string, message: string): string {
	const DEFAULT_PADDING: number = 2;
	const MILLISECOND_PADDING: number = 3;
	const now: Date = new Date();
	const hours: string = padLeft(now.getHours(), DEFAULT_PADDING);
	const minutes: string = padLeft(now.getMinutes(), DEFAULT_PADDING);
	const seconds: string = padLeft(now.getSeconds(), DEFAULT_PADDING);
	const milliseconds: string = padLeft(now.getMilliseconds(), MILLISECOND_PADDING);
	return `[${hours}:${minutes}:${seconds}.${milliseconds} @${tag}]\t${message}`;
}

function setLogLevel(level: number): void {
	logLevel = level;
	console.info(formatMessage("log", `Log level set to ${logLevel} (errors: ${LOG_LEVEL_ERROR >= logLevel ? "yes": "no"} warnings: ${LOG_LEVEL_WARNING >= logLevel ? "yes": "no"} information: ${LOG_LEVEL_INFORMATION >= logLevel ? "yes": "no"})`));
}

export function log(tag: string, message: string): void {
	if (LOG_LEVEL_INFORMATION >= logLevel) {
		console.info(formatMessage(tag, message));
	}
}

export function logWarning(tag: string, message: string): void {
	if (LOG_LEVEL_WARNING >= logLevel) {
		console.warn(formatMessage(tag, message));
	}
}

export function logError(tag: string, error: CustomError): void {
	if (LOG_LEVEL_ERROR >= logLevel) {
		console.error(formatMessage(tag, `[Error code:${error.code} name:${error.name}] ${error.message}`));
	}
}

// Check if an environment variable has been set to control the log level.
const envValue: string = process.env.VUE_APP_LOG_LEVEL as string;
if (envValue) {
	if (envValue === "information" || envValue === "info") {
		setLogLevel(LOG_LEVEL_INFORMATION);
	}
	else if (envValue === "warning" || envValue === "warn") {
		setLogLevel(LOG_LEVEL_WARNING);
	}
	else if (envValue === "none" || envValue === "") {
		setLogLevel(LOG_LEVEL_NONE);
	}
}
