/* eslint-disable max-depth */
import { EndpointRequest } from "./endpoint-request";
import type { EndpointErrorResponse, EndpointResponse } from "./types";
import { CustomErrorCode, type CustomError } from "@/utilities/custom-error";
import { getIDToken } from "@/data/providers/authentication-provider";
import { user } from "../providers/user-provider";
import { PagingInfoModel } from "@/data/models/paging-info-model";

function createEndpointResponse<T>(): EndpointResponse<T> {
	return {
		data: undefined,
		hasError: false,
		wasSuccessful: false,
		error: {
			code: 0,
			name: "",
			message: ""
		}
	};
}

async function getHeaders(): Promise<HeadersInit> {
	const headers: HeadersInit = {
		"Authorization": `Bearer ${await getIDToken()}`,
		"Content-Type": "application/json"
	};
	if (user.divisionID) {
		Object.assign(headers, {
			"Fulfilmentcrowd-Division-Id": user.divisionID,
		});
		if (user.current?.language) {
			Object.assign(headers, {
				"Fulfilmentcrowd-Language-Code": user.current.language,
			});
		}
	}

	return headers;
}

async function createRequestInitializer(request: EndpointRequest, method: string): Promise<RequestInit> {
	const initializer: RequestInit = {
		method,
		headers: await getHeaders(),
		body: request.body
	};
	if (request.abortController) { 
		initializer.signal = request.abortController.signal;
	}
	return initializer;
}

export async function endpointFetchAsync<T>(method: string, request: EndpointRequest): Promise<EndpointResponse<T>> {
	const result: EndpointResponse<T> = createEndpointResponse();

	try {
		const fetchInitializer: RequestInit = await createRequestInitializer(request, method);
		const response: Response = await fetch(request.url, fetchInitializer); 
		if (!response.ok) {
			result.error.code = response.status;
			const errorResponse: EndpointErrorResponse = await response.json();
			if (errorResponse) {
				result.hasError = true;
				if (!errorResponse.error) {
					errorResponse.error = {code: 0, message: "", name: ""};
				}
				if (errorResponse["Error"]) {
					if (errorResponse["Error"].Code) {
						errorResponse.error.code = errorResponse["Error"]["Code"];
					}
					if (errorResponse["Error"].Message) {
						errorResponse.error.message = errorResponse["Error"]["Message"];
					}
				}
				else {
					if (errorResponse["error"].code) {
						errorResponse.error.code = errorResponse.error.code;
					}
					if (errorResponse["error"].message) {
						errorResponse.error.message = errorResponse.error.message;
					}
					result.error.message = errorResponse.error.message;
				}
			}
			else {
				result.error.message = response.statusText;
			}
		}
		else {
			if (request.isBlob) {
				result.data = await response.blob() as unknown as T;	
			}
			else {
				if (response.status !== 204) {
					// Only attempt to use the response body when content was returned
					result.data = await response.json() as T;	
				}
			}
			result.wasSuccessful = true;
		}
	}
	catch (e) {
		const error: CustomError = e as CustomError;
		result.hasError = true;
		if (error.code) {
			result.error.code = error.code;
		}
		else {
			result.error.code = CustomErrorCode.FetchEndpointFailure;
		}
		if (error.name) {
			result.error.name = error.name;
		}		
		if (error.message && !result.error.message) {
			result.error.message = error.message;
		}
	}
	return result;
}

/**
 * Creates a standard endpoint for getting data, with optional paging info and/or search term parameters.
 */
function createGetEndpointRequest(url: string, pagingInfo?: PagingInfoModel, searchTerm?: string): EndpointRequest {
	const endpointRequest: EndpointRequest = new EndpointRequest(url);

	if (pagingInfo) {
		endpointRequest.appendQueryParameter("offset", pagingInfo.offset);
		endpointRequest.appendQueryParameter("limit", pagingInfo.limit);
	}

	if (searchTerm && searchTerm?.length > 0) {
		// Encode the search term parameter.
		endpointRequest.appendQueryParameter("search_term", encodeURIComponent(searchTerm));
	}
	return endpointRequest;
}

/**
 * Send a GET request to the specified endpoint url, optionally passing values for standard paging info and/or (UN-ENCODED) search term parameters.
 * Note - the search term should be passed as a regular UN-ENCODED string, as it gets encoded automatically when the endpoint is created.
 */
export async function getAsync<T>(url: string, pagingInfo?: PagingInfoModel, searchTerm?: string): Promise<EndpointResponse<T>> {
	return endpointFetchAsync<T>("GET", createGetEndpointRequest(url, pagingInfo, searchTerm));
}

/**
 * Send a POST request to the specified endpoint url, optionally passing json string content in the body of the request.
 */
export async function postAsync<T>(url: string, jsonContent?: BodyInit, isBlob?: boolean, isText?: boolean): Promise<EndpointResponse<T>> {
	return endpointFetchAsync<T>("POST", new EndpointRequest(url, false, jsonContent, isBlob, isText));
}

/**
 * Send a PATCH request to the specified endpoint url, passing json string content in the body of the request.
 */
export async function patchAsync<T>(url: string, jsonContent: BodyInit): Promise<EndpointResponse<T>> {
	return endpointFetchAsync<T>("PATCH", new EndpointRequest(url, false, jsonContent, false));
}

/**
 * Send a DELETE request to the specified endpoint url.
 */
export async function deleteAsync<T>(url: string): Promise<EndpointResponse<T>> {
	return endpointFetchAsync<T>("DELETE", new EndpointRequest(url, false, undefined, false));
}
