import { App } from "vue";
import { LayoutName } from "./constants";

import MobileLayout from "./mobile-layout/mobile-layout.vue";

/**
 * Export & install layouts for use globally
 */
export default {
	install(app: App): void {
		app.component(LayoutName.MobileLayout, MobileLayout);
	}
};