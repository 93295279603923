import { cva } from "class-variance-authority";

// Component model for the FcHeader component
class FcHeaderModel {
	/**
	 * Returns list of classes for a specific header variant
	 */
	public variants: Function = cva(["w-full", "pt-safe", "border-b"], {
		variants: {
			color: {
				white: ["bg-white", "border-gray-300"],
				brand: ["bg-brand", "border-primary"],
			}
		},
		defaultVariants: {
			color: "white"
		}
	});
}

// Export the component model
export const component: FcHeaderModel = new FcHeaderModel();