import { endpointFetchAsync } from "../endpoint/endpoint";
import { EndpointRequest } from "../endpoint/endpoint-request";
import type { EndpointResponse } from "../endpoint/types";

// Define app config class 
class AppConfig {
	public configuration: Configuration = {};
	public features: Feature = {};
} 

// Export app config to application
export const appConfig: AppConfig = {
	configuration: {},
	features: {}
};

// Define configuration endpoint model
export interface ConfigModel {
	configuration: Configuration;
	features: Feature;
}

// Define model for configuration key-value pairs
interface Configuration {
	[key: string]: string;
}

// Define model for feature key-value pairs
interface Feature {
	[key: string]: boolean;
}

/**
 * Get Application Configuration from API
 * @returns Promise
 */
export function getAppConfigAsync(): Promise<EndpointResponse<ConfigModel>> {
	return endpointFetchAsync("GET", new EndpointRequest(`configuration`));
}