import { CarrierServiceModel } from "@/data/models/carrier-service-model";
import { PackageModel } from "@/data/models/package-model";
import { PackagesModel } from "@/data/models/packages-model";
import { StoreCageModel } from "@/data/models/store-cage-model";
import { FcStatusScreen } from "@/fc-components/fc-status-screen/fc-status-screen-types";

/**
 * View state for the scan to cage view.
 */
export class ScanToCageState {
	/**
	 * Stores the barcode for the package.
	 */
	public barcode: string = "";

	/**
	 * Stores the scanned cage.
	 */
	public cage: StoreCageModel | undefined;

	/**
	 * Stores a list of package details.
	 */
	public packages: PackagesModel | undefined;

	/**
	 * Stores a list of compatible carrier services.
	 */
	public compatibleCarrierServices: Array<CarrierServiceModel> = [];

	/**
	 * True if the modal is busy doing something.
	 */
	public isBusy: boolean = false;

	/**
	 * Stores content for the status screen.
	 */
	public statusScreenContent: FcStatusScreen | undefined;

	/**
	 * Stores content for the dialog.
	 */
	public dialogContent: object | undefined;
}