// Import Vue setup
import { App } from "vue";

// Import component names
import { ComponentName } from "./constants";
import FcVStack from "./fc-v-stack/fc-v-stack.vue";
import FcHStack from "./fc-h-stack/fc-h-stack.vue";
import FcButton from "./fc-button/fc-button.vue";
import FcIcon from "./fc-icon/fc-icon.vue";
import FcHeader from "./fc-header/fc-header.vue";
import FcHeading from "./fc-heading/fc-heading.vue";
import FcListItem from "./fc-list-item/fc-list-item.vue";
import FcInput from "./fc-input/fc-input.vue";
import FcStatusScreen from "./fc-status-screen/fc-status-screen.vue";
import FcDialog from "./fc-dialog/fc-dialog.vue";
import FcLoader from "./fc-loader/fc-loader.vue";

// Export + Install custom components within the application
export default {
	install(app: App): void {
		app.component(ComponentName.FcHStack, FcHStack);
		app.component(ComponentName.FcVStack, FcVStack);
		app.component(ComponentName.FcButton, FcButton);
		app.component(ComponentName.FcIcon, FcIcon);
		app.component(ComponentName.FcHeader, FcHeader);
		app.component(ComponentName.FcHeading, FcHeading);
		app.component(ComponentName.FcListItem, FcListItem);
		app.component(ComponentName.FcInput, FcInput);
		app.component(ComponentName.FcStatusScreen, FcStatusScreen);
		app.component(ComponentName.FcDialog, FcDialog);
		app.component(ComponentName.FcLoader, FcLoader);
	}
};