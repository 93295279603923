import { createRouter, createWebHistory, Router, RouteRecordRaw } from "vue-router";
import { scanToCageRoutes } from "./views/scan-to-cage/routes";

const routes: Array<RouteRecordRaw> = [
	...scanToCageRoutes
];

const router: Router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
