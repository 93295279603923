import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "@/assets/css/tailwind.css";
import FcComponents from "./fc-components";
import { ScreenUtilities } from "./utilities/screen-utilities";
import "@/data/providers/icon-provider";
import { LocalizationUtilities } from "./utilities/localization-utilities";
import { authenticateUser } from "./data/providers/authentication-provider";
import * as UserProvider from "./data/providers/user-provider";
import * as ConfigProvider from "./data/providers/config-provider";
import { EndpointResponse } from "./data/endpoint/types";
import Layouts from "./layouts";
import "@capacitor/browser";

function startApplication(): void {
	LocalizationUtilities.loadPreferredLanguageAsync().finally(() => {
		ScreenUtilities.listenForScreenSizeChange();
		createApp(App)
			.use(router)
			.use(FcComponents)
			.use(Layouts)
			.mount("#app");
	});
}

function setupApplication(): void {
	authenticateUser().then(async () => {
		await UserProvider.getUserDetailsFromApi();
		startApplication();
	});
}

ConfigProvider.getAppConfigAsync().then((response: EndpointResponse<ConfigProvider.ConfigModel>) => {
	if (response.wasSuccessful && response.data) {
		ConfigProvider.appConfig.configuration = response.data.configuration;
		ConfigProvider.appConfig.features = response.data.features;
		setupApplication();
	}
});
