import type { RouteRecordRaw } from "vue-router";
import { View } from "@/views/constants";

import ScanToCage from "./scan-to-cage.vue";

export const scanToCageRoutes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: View.ScanToCage,
		component: ScanToCage,
		meta: {
			key: "scan-to-cage"
		}
	}
];

