import { cva } from "class-variance-authority";

// Component model for the FcButton component
class FcButtonModel {
	/**
	 * Returns list of classes for a specific button variant
	 */
	public variants: Function = cva(["inline-flex", "items-center", "justify-center", "font-medium", "font-rubik", "focus:outline-none", "space-x-2", "rounded-full", "text-sm", "p-2.5", "px-6", "transition-all", "focus:outline-none", "focus:ring-2", "focus:ring-offset-2", "hover:bg-opacity-80"], {
		variants: {
			color: {
				blackcurrant: ["text-white", "bg-blackcurrant-500", "ring-blackcurrant-400"],
				raspberry: ["text-white", "bg-raspberry-500", "ring-raspberry-400"]
			},
			isFullWidth: {
				true: "w-full"
			},
			isDisabled: {
				true: ["!bg-gray-300", "!text-white", "!cursor-not-allowed", "focus:!ring-gray-300"]
			},
		},
		defaultVariants: {
			color: "blackcurrant"
		}
	});
}

// Export the component model
export const component: FcButtonModel = new FcButtonModel();