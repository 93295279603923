import { Capacitor } from "@capacitor/core";
import { DataWedge, ScanListenerEvent } from "capacitor-datawedge";

/**
 * Utility functions for Datawedge scanners.
 */
export class DatawedgeUtilities {
	/**
	 * True if current device has a datawedge scanner.
	 */
	public static isDatawedgeAvailable(): boolean {
		return Capacitor.isPluginAvailable("DataWedge");
	}
    
	/**
	 * Register scan listener to listen for barcode data from datawedge scanner.
	 */
	public static async registerScanListener(onScan: Function): Promise<void> {
		await DataWedge.addListener("scan", (response: ScanListenerEvent) => {
			onScan(response);
		});
	}

	/**
	 * Starts software scanning trigger.
	 */
	public static async startSoftScan(): Promise<void> {
		await DataWedge.startScanning();
	}

	/**
	 * Stops software scanning trigger.
	 */
	public static async stopSoftScan(): Promise<void> {
		await DataWedge.stopScanning();
	}

	/**
	 * Enables datawedge scanner.
	 */
	public static async enableScanning(): Promise<void> {
		await DataWedge.enableScanner();
	}

	/**
	 * Disables datawedge scanner.
	 */
	public static async disableScanning(): Promise<void> {
		await DataWedge.disableScanner();
	}
}