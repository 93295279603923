import { cva } from "class-variance-authority";

// Component model for the status screen component
class FcStatusScreenModel {
	/**
	 * Returns list of classes for a specific status screen variant
	 */
	public variants: Function = cva(["h-screen", "w-screen", "absolute", "inset-0", "flex", "items-center", "justify-center", "!m-0", "p-4", "z-50"], {
		variants: {
			color: {
				blue: "bg-blue-500",
				red: "bg-red-500",
				green: "bg-green-500",
			}
		},
		defaultVariants: {
			color: "blue"
		}
	});
}

// Export the component model
export const component: FcStatusScreenModel = new FcStatusScreenModel();