import { log, logWarning } from "@/utilities/log";

// Get the API url from environment variable.
const BASE_API_URL: string = process.env.VUE_APP_API_URL as string;

export class EndpointRequest {
	private _url: string;
	private _body: BodyInit | null | undefined;
	private _abortController: AbortController | undefined;
	private _isBlob: boolean | undefined;
	private _isText: boolean | undefined;

	public getApiPath(resourcePath: string, customURL: boolean | undefined): string {
		let path: string = `${BASE_API_URL}/${resourcePath}`;
		if (customURL) {
			path = resourcePath;
		}
		return path;
	}

	constructor(resourcePath: string, customURL?: boolean, body?: BodyInit | null | undefined, isBlob?: boolean, isText?: boolean) {
		this._url = this.getApiPath(resourcePath, customURL);
		this._body = body;
		this._isBlob = isBlob;
		this._isText = isText;
	}

	/**
	 * Append a query parameter to the endpoint url. Would be nice to use the native URL() object here, but safari was late to adopt it.
	 */
	public appendQueryParameter(name: string, value: string | number): void {
		if (!this._url.includes("?")) {
			// Add the query symbol when the url does not already contain it.
			this._url += `?${name}=${value}`;
		}
		else {
			// The url already contains a query, so the parameter name needs prefixing with an & parameter separator.
			this._url += `&${name}=${value}`;
		}
	}

	public get url(): string {
		return this._url;
	}

	public get body(): BodyInit | null | undefined {
		return this._body;
	}

	public get isBlob(): boolean | undefined {
		return this._isBlob;
	}

	public get isText(): boolean | undefined {
		return this._isText;
	}

	public ensureAbortable(): void {
		if (this._abortController === undefined) {
			this._abortController = new AbortController();
		}
	}

	public get abortController(): AbortController | undefined {
		return this._abortController;
	}

	public abort(): void {
		if (this._abortController !== undefined) {
			this._abortController.abort();
		}
		else {
			logWarning("EndpointRequest", "Cannot abort request without calling ensureAbortable().");
		}
	}
}
