/**
 * Enumeration of all the available components.
 */
export enum ComponentName {
	FcHStack = "FcHStack",
	FcVStack = "FcVStack",
	FcButton = "FcButton",
	FcIcon = "FcIcon",
	FcHeader = "FcHeader",
	FcHeading = "FcHeading",
	FcListItem = "FcListItem",
	FcInput = "FcInput",
	FcStatusScreen = "FcStatusScreen",
	FcDialog = "FcDialog",
	FcLoader = "FcLoader"
}