import { cva } from "class-variance-authority";

// Component model for the V Stack component
class FcVStackModel {
	/**
	 * Returns list of classes for a specific button variant
	 */
	public variants: Function = cva(undefined, {
		variants: {
			spacing: {
				0: "space-y-0",
				2: "space-y-2",
				4: "space-y-4",
				5: "space-y-5",
				6: "space-y-6",
				8: "space-y-8"
			},
			align: {
				start: ["h-full", "w-full", "flex", "flex-col", "items-start", "justify-center", "text-left"],
				center: ["h-full", "w-full", "flex", "flex-col", "items-center", "justify-center", "text-center"],
				end: ["h-full", "w-full", "flex", "flex-col", "items-end", "justify-center", "text-right"],
			},
			isFullHeight: {
				true: "h-full"
			}
		},
		defaultVariants: {
			spacing: 0
		}
	});
}

// Export the component model
export const component: FcVStackModel = new FcVStackModel();